import '../App.css';
import NavigationBar from './components/NavigationBar.tsx';
import React from 'react';

function AccountDeletionScreen() {

  return (
    <div className="App">
      <div className="flex overflow-hidden flex-col pt-7 bg-neutral-800">
        <NavigationBar/>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/7fb44e47c4c546598882165c540ccdd3c093518748190bc27ebbd310d3eca382?apiKey=3994eb2c170b47cd83412d68bba28691&"
          alt=""
          className="object-contain z-10 self-end mt-28 mr-32 aspect-[1.01] w-[94px] max-md:mt-10 max-md:mr-2.5"
        />

        <main className="container pb-5">
          <section>
            <h1 className="text-7xl pb-5 text-white font-medium leading-[101px] lg:max-w-full md:text-7xl md:leading-[89px] font-cocon max-sm:text-4xl">
              Account Deletion Process
            </h1>

            <div className="row">
              <div className="col-6 align-content-center">
                <h3 className="mt-8 text-4xl font-bold text-white">Go to Our App</h3>
                <p className="mt-8 text-3xl text-white max-sm:mb-5">
                  Launch the app and get started by opening TheYellowApp.
                </p>
              </div>
              <div className="col-6">
                <img src="/images/sign-in.png" alt=""
                     className="img-fluid ml-auto"/>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <img src="/images/sign-in-data.png" alt=""
                     className="img-fluid mr-auto"/>
              </div>
              <div className="col-6 align-content-center">
                <h3 className="mt-8 text-4xl font-bold text-white">
                  Sign In with Your Credentials
                </h3>
                <p className="mt-8 text-3xl text-white max-sm:mb-5">
                  Enter your login details and tap the "Sign In" button to proceed.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6 align-content-center">
                <h3 className="mt-8 text-4xl font-bold text-white">
                  Navigate to Your Profile
                </h3>
                <p className="mt-8 text-3xl text-white max-sm:mb-5">
                  After successful login, you'll be directed to your Book screen.
                  Tap on your profile picture located at the top right corner of the screen.
                </p>
              </div>
              <div className="col-6">
                <img src="/images/book-screen.png" alt=""
                     className="img-fluid ml-auto"/>
              </div>
            </div>

            <div className="row pb-5">
              <div className="col-6">
                <img src="/images/profile-settings.png" alt=""
                     className="img-fluid mr-auto"/>
              </div>
              <div className="col-6 align-content-center">
                <h3 className="mt-8 text-4xl font-bold text-white">
                  Enter Profile Settings
                </h3>
                <p className="mt-8 text-3xl text-white max-sm:mb-5">
                  You’ll now be on the Profile Settings page. Scroll to the bottom of your Profile Settings, and
                  you'll see the "Delete Account" option. Hit the "Delete Account" button to initiate the account deletion process.
                </p>
              </div>
            </div>
          </section>

          <section className="pb-5">
            <h2 className="self-stretch mt-5 pb-3 text-4xl font-bold text-center text-yellow-400 max-md:max-w-full">
              Data Deletion Information:
            </h2>
            <p className="mt-8 text-3xl text-white max-sm:mb-5">
              Upon deleting your account, the following data will be permanently deleted:
            </p>
            <p className="mt-8 text-3xl text-white max-sm:mb-5">
              &#9642; Personal Profile Information (Name, Email, etc.) &#9642; Book Data (History) &#9642; User-Generated Content (Reviews, Comments)
            </p>
          </section>

          <section>
            <h2 className="self-stretch mt-5 pb-3 text-4xl font-bold text-center text-yellow-400 max-md:max-w-full" style={{marginTop: '5rem'}}>
              Data Retention
            </h2>
            <p className="mt-8 text-3xl text-white max-sm:mb-5">
              Data kept for a retention period of 30 days in case of accidental deletion or to facilitate troubleshooting.
            </p>
            <p className="mt-8 text-3xl text-white max-sm:mb-5">
              Please note that once the account is permanently deleted, no data can be recovered.
            </p>
            <p className="mt-8 text-3xl text-white max-sm:mb-5">
              For further support, feel free to reach out to us at: <a href="mailto:admin@theyellowapp.com" className="text-yellow-400 text-decoration-none">admin@theyellowapp.com</a>
            </p>
          </section>
        </main>
      </div>

    </div>
  );
}

export default AccountDeletionScreen;
