import { useState, useRef, useEffect } from "react";
import QRCode from "react-qr-code";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import FeatherIcon from 'feather-icons-react';

function QRCodeScanner() {
  useEffect(() => {
    handleQrCodeGenerator();
  }, []);

  const qrCodeRef = useRef(null);
  const [url, setUrl] = useState("https://app-dev.theyellowapp.com/verifyQR?bookId=1&qrCodeId=1&contentype=video&sequential=0");
  const [qrIsVisible, setQrIsVisible] = useState(false);

  const handleQrCodeGenerator = () => {
    if (!url) {
      return;
    }
    setQrIsVisible(true);
  };

  return (
    <div className="qrcode__container">
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">
            <img src={`/logo192.png`} alt="logo" style={{ height: 80 }} />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <a href="/"><FeatherIcon icon="home" size="40" /></a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <h1 className="text-center mt-5 mb-5">QR Code For Video</h1>
      
      <div className="qrcode__container--parent d-flex justify-content-center align-items-center" ref={qrCodeRef} style={{ minHeight: '400px' }}>
        {qrIsVisible && (
          <div className="qrcode__download">
            <div className="qrcode__image">
              <QRCode value={url} size={400} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default QRCodeScanner;
