import { useState, useEffect } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import Row from 'react-bootstrap/Row';
import { useSearchParams } from 'react-router-dom';

const VerifyQR = (props) => {
  const [searchParams] = useSearchParams();
  const bookId = searchParams.get('bookId');
  const qrCodeId = searchParams.get('qrCodeId');
  const contentype = searchParams.get('contentype');
  const sequential = searchParams.get('sequential');

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const videoContent = `linking-app://app/VerifySequence?contentype=${contentype}&qrcodeID=${qrCodeId}&bookId=${bookId}&sequential=${sequential}`;
    const intermissionContent = `linking-app://app/Comments?contentype=${contentype}&qrcodeID=${qrCodeId}&bookId=${bookId}`;

    
    // Detect device type
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    
    // Fallback URLs for app stores
    const fallbackURL = isAndroid
      ? 'http://app-dev.theyellowapp.com/androidstore'
      : isIOS
      ? 'http://app-dev.theyellowapp.com/iosstore'
      : 'https://app.theyellowapp.com';

    const openApp = () => {
      if (contentype === "video") {
        console.log("Opening video content", videoContent);
        window.location.href = videoContent;
      } else if (contentype === "intermission") {
        console.log("Opening intermission content", intermissionContent);
        window.location.href = intermissionContent;
      }

      // Fallback to respective app store after 2 seconds if the app isn't installed
      setTimeout(() => {
        window.location.href = fallbackURL;
      }, 5000);
    };

    openApp();
  }, [bookId, qrCodeId, contentype]);

  return (
    <div>
      <div className="d-flex justify-content-center mt-5">
        <img src={`/logo192.png`} alt="logo" style={{ height: 150, marginTop: 20, marginBottom: 100 }} />
      </div>
      <Row className="text-center mt-5">
        <BounceLoader
          cssOverride={{ display: "block", margin: "0 auto" }}
          size={80}
          color={"#ffd600"}
          loading={loading}
          speedMultiplier={1}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </Row>
      <Row className="text-center mt-2">
        <strong style={{ fontSize: 23 }}>Verifying QR Code... </strong>
      </Row>
    </div>
  );
};

export default VerifyQR;
